<template>
  <div class="custom-tooltip" v-if="visible">
    <!-- <span>Value: <strong>{{ thisdata.oldValue }}</strong></span> -->
    <!-- <br> -->
    <div v-for="(data, index) in this.data.errorColumn" :key="index">
      <strong>Field: {{ data.columnName }}</strong>
      <br>
      <span class="mb-2">Note: {{ data.toolTip }}</span>
      <br>
      <br>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      visible: true,
      thisdata: {
        pesan: '',
        oldValue: '',
      },
      data: null,
      color: null,
    }
  },
  beforeMount() {
    this.data = this.params.api.getDisplayedRowAtIndex(
      this.params.rowIndex,
    ).data
    // console.log('this.data', this.data)
    // console.log('this.params', this.params)
    var indexError = this.data.errorColumn.findIndex(x => x.columnName.toLowerCase() === this.params.colDef.field.toLowerCase())
    // console.log('indexError', indexError)
    if (indexError >= 0) {
      this.color = this.params.color || 'white'
      this.visible = true
      this.thisdata.pesan = this.data.errorColumn[indexError].toolTip
    } else {
      // this.visible = false
      this.pesan = ''
    }
    // if (this.data.error !== undefined) {
    //   var target = this.data.error.find(x => x.field === this.params.colDef.field)
    //   // console.log('target', target)
    //   if (target !== undefined) {
    //     this.color = this.params.color || 'white'
    //     this.visible = true
    //     this.pesan = target.error
    //   } else {
    //     this.visible = false
    //     this.pesan = ''
    //   }
    // // console.log('this.visible', this.visible)
    // }
  },
})
</script>

<style>
.custom-tooltip {
  position: absolute;
  background-color: black;
  border: 1px solid;
  color: white;
  padding: 5px;
  border-radius: 6px;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

/* .custom-tooltip:hover p div span strong {
  visibility: visible;
} */

.custom-tooltip p:first-of-type {
  font-weight: bold;
}
</style>
