<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <strong>{{ title }}</strong>
            <a-button
              class="btn btn-outline-primary ml-2"
              style="width: 100px;"
              :loading="btnValidateLoading"
              @click="tryToValidate"
              >Validate</a-button
            >
            <!-- <a-switch class="ml-3" v-model="justError" @change="onlyError">
              <a-icon slot="checkedChildren" type="check" />
              <a-icon slot="unCheckedChildren" type="close" />
            </a-switch>
            <span class="ml-2">Only error table</span> -->
            <a-button
              class="btn btn-outline-success ml-5"
              @click="saveChanges"
              v-if="changedData.length !== 0"
              >Save Changes</a-button
            >
            <!-- <a-button
              class="btn btn-outline-danger ml-2"
              @click="cancelaction"
              v-if="changedData.length !== 0"
            >
              Cancel
            </a-button> -->
          </div>
          <a-card
            class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3 ml-2 mr-2"
          >
            <div class="row" :key="componentKey">
              <div
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2"
                v-for="(data, index) in tableData"
                :key="index"
              >
                <!-- v-if="data.errData.length !== 0" -->
                <!-- <div v-if="justError">
                  <strong v-if="data.errData.length !== 0">
                    {{ data.notes }} <a-icon v-if="data.errData !== null" type="check" :class="data.errData.length === 0 ? 'text-success' : 'text-danger'"/><a-icon type="close" class="text-danger" v-else/>
                  </strong>
                </div> -->
                <strong>
                  {{ data.notes }}
                  <a-icon
                    v-if="data.errData !== null"
                    type="check"
                    :class="
                      data.errData.length === 0 ? 'text-success' : 'text-danger'
                    "
                  /><a-icon type="close" class="text-danger" v-else />
                </strong>
                <ag-grid-vue
                  v-if="data.errData !== null && data.errData.length !== 0"
                  style="height: 150px;"
                  :class="
                    settings.theme === 'dark'
                      ? 'ag-theme-balham-dark mt-2'
                      : 'ag-theme-balham mt-2'
                  "
                  :tooltipShowDelay="100"
                  :gridOptions="gridOptions"
                  :columnDefs="data.column"
                  :pagination="allowPagination"
                  :paginationPageSize="15"
                  :accentedSort="false"
                  :sortingOrder="['asc', 'desc']"
                  :rowData="data.errData"
                  @grid-ready="(e) => onGridReady(e, index)"
                  :singleClickEdit="false"
                  @cell-value-changed="onCellValueChanged"
                  :undoRedoCellEditing="true"
                  :undoRedoCellEditingLimit="5"
                  :frameworkComponents="frameworkComponents"
                  rowSelection="single"
                  :rowClassRules="rowClassRules"
                >
                  <!-- @selection-changed="onSelectionChanged" -->
                  <!-- @first-data-rendered="onFirstDataRendered" -->
                </ag-grid-vue>
              </div>
            </div>
          </a-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import numericInput from './component/numeric'
import selectInput from './component/select'
import dateInput from './component/date'
import tooltip from './component/tooltip'
import htmlrender from './component/htmlrender'
import { mapState } from 'vuex'
import { Modal } from 'ant-design-vue'
export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  watch: {
    excelModalVisible(newValue, oldValue) {
      if (newValue === false) {
        // console.log('oldValue', oldValue)
        this.excelModalCancel()
        // console.log('newValue', newValue)
      }
    },
  },
  data() {
    return {
      title: 'Validasi data',
      mode: 'netral',
      justError: false,
      theme: this.$store.state.settings.theme,
      dataCabangList: [],
      modal: {
        visible: false,
        periode: this.$store.state.user.periode,
        nomor_surat: '',
      },
      tablequickfilter: '',
      gridOptions: null,
      gridOptionsTotal: null,
      kodeCabang: 'all',
      allowPagination: localStorage.getItem('gridpagination') || false,
      frameworkComponents: {
        numericEditor: numericInput,
        dateEditor: dateInput,
        selectEditor: selectInput,
        customTooltip: tooltip,
        htmlRender: htmlrender,
      },
      rowClassRules: {
        'text-success': (params) => {
          return params.data.newdata !== undefined
        },
        'text-warning': (params) => {
          return params.data.edited !== undefined
        },
        'text-danger': (params) => {
          return params.data.isError !== undefined
        },
        'text-primary': (params) => {
          return params.data.isPrimary !== undefined && params.data.isPrimary === 1
        },
        'font-weight-bold': (params) => {
          return params.data.isBold !== undefined
        },
      },
      columnDefs: [],
      columnDefsTotal: [],
      selectedData: null,
      gridApi: null,
      backupRowData: [],
      rowData: null,
      rowDataTotal: null,
      changedData: [],
      tableData: [],
      justErrorData: [],
      columnCurrency: [],
      input: {
        undoInput: '',
        redoInput: '',
        undoInputdisable: true,
        redoInputdisable: true,
      },
      btnValidateLoading: false,
      dataResponse: [],
      rowAfterFilter: [],
      componentKey: 0,
      allGridConfig: [],
    }
  },
  created() {
    this.getAllMaster()
    // console.log('this.allMaster', this.allMaster)
    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
    // console.log('this.kodeCabang', this.kodeCabang)
    // this.getColumnDefs()
    // this.getAllData()
  },
  methods: {
    moment,
    // handleMenuClick(e) {
    //   console.log('click', e)
    // },
    onlyError(checked) {
      // this.tableData = this.$g.clone(this.dataResponse)
      this.componentKey++
    },
    async tryToValidate() {
      Modal.confirm({
        title: 'Validate?',
        content: 'Validasi semua form!',
        okText: 'Yes',
        okType: 'primary',
        cancelText: 'No',
        onOk: async () => {
          var res = await lou.customUrlGet('master/validate')
          if (res) {
            this.btnValidateLoading = true
            this.dataResponse = res.data
            this.backupRowData = this.$g.clone(this.dataResponse)
            this.getAllData()
            // this.rowData = []
            // this.backupRowData = []
            // this.rowDataTotal = []
          }
        },
        onCancel: () => {
          // console.log('Cancel')
          return false
        },
      })
    },
    paginationruleschange() {
      this.allowPagination = !this.allowPagination
      localStorage.setItem('gridpagination', this.allowPagination)
      // location.reload()
      this.componentKey += 1
    },
    showModal() {
      this.modal.visible = true
    },
    filterKodeCabang() {
      if (this.kodeCabang === 'all') {
        this.rowAfterFilter = this.$g.clone(this.backupRowData)
      } else {
        var newdata = this.backupRowData.filter(
          (x) => x.sandikantor === this.kodeCabang,
        )
        this.rowAfterFilter = this.$g.clone(newdata)
      }
      this.rowData = this.rowAfterFilter
      this.hitungTotal()
    },
    async getAllMaster() {
      // console.log('this.settings.whatapp', this.settings)
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) => x.mykey === 'allmaster' + this.settings.whatapp.appName.toLowerCase() + this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res = await lou.customUrlGet('master', false, true)
        var master = {
          mykey: 'allmaster' + this.settings.whatapp.appName.toLowerCase(),
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) => x.mykey === 'allmaster' + this.settings.whatapp.appName.toLowerCase() + this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      allmaster.cabanglist = cabanglist
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      // this.getColumnDefs()
      // this.getAllData()
    },
    async getAllData() {
      // console.log('res', res.data)
      var testData = this.$g.clone(this.dataResponse)
      for (let i = 0; i < testData.length; i++) {
        const el = testData[i]
        el.column = await this.getColumn(el.formName)
      }
      // console.log('testData', testData)
      var filtered = testData.filter(
        (x) => x.errData === null || x.errData.length !== 0,
      )
      // console.log('filtered', filtered)
      this.justErrorData = this.$g.clone(filtered)
      this.tableData = this.justError
        ? this.$g.clone(filtered)
        : this.$g.clone(testData)
      this.btnValidateLoading = false
      // this.rowData = []
    },
    async getColumn(paramdiatas) {
      var nindexeddb = await lou.getDataFromDb()
      // console.log('nindexxeddb', nindexeddb)
      var gcIndexTarget = nindexeddb.findIndex(
        (x) => x.mykey === 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id,
      )
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var column = []
      var res = null
      // console.log('res.data', res.data)
      // console.log('gcIndexTarget', gcIndexTarget)
      if (gcIndexTarget < 0) {
        res = await lou.customUrlGet('master/tables', false, true)
        // console.log('res.data', res.data)
        column = res.data[paramdiatas]
        var master = {
          mykey: 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id,
          data: JSON.stringify(res.data),
        }
        await lou.addDataToDb(master)
      } else {
        var dataFDB = JSON.parse(nindexeddb[gcIndexTarget].data)
        this.allGridConfig = dataFDB
        // console.log('dataFDB', dataFDB)
        // console.log('paramdiatas', paramdiatas)
        if (dataFDB[paramdiatas] !== undefined) {
          column = dataFDB[paramdiatas]
        } else {
          await lou.deleteDataFromDb(nindexeddb[gcIndexTarget].id)
          res = await lou.customUrlGet('master/tables', false, true)
          column = res.data[paramdiatas]
          // console.log('column', column)
          var masters = {
            mykey: 'allGridConfig' + this.settings.whatapp.appName + this.settings.whatapp.id,
            data: JSON.stringify(res.data),
          }
          await lou.addDataToDb(masters)
        }
        // console.log('column', column)
      }
      var columnDefs = []
      var contohdata = {}
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        // console.log('element', element)
        var data = element
        if (data.isHide === 'false') {
          if (data.valueFormatter === '') {
            delete data.valueFormatter
          } else {
            if (data.valueFormatter === 'currency') {
              this.columnCurrency.push({
                field: data.field.toLowerCase(),
                headerName: data.headerName,
                valueFormatter: this.currencyFormatter,
                resizable: true,
              })
              data.valueFormatter = this.currencyFormatter
            }
          }
          if (data.reftable !== undefined && data.reftable !== '' && data.reftable !== null) {
            var object = this.allMaster[data.reftable]
            if (object !== undefined) {
              data.cellEditor = 'selectEditor'
              data.cellEditorParams = {
                values: object,
                reffield: data.retreffield,
              }
            }
          }
          if (data.cellEditor === '') delete data.cellEditor
          if (data.pinned === '') delete data.pinned
          data.editable = data.editable === 'true'
          // data.editable = false
          if (data.filter === 'true') {
            data.filter = true
          } else if (data.filter === 'false') {
            data.filter = false
          } else if (data.filter === 'agDateColumnFilter') {
            data.filterParams = {
              comparator: (filterLocalDateAtMidnight, cellValue) => {
                var dateAsString = cellValue
                if (dateAsString == null) return -1
                var dateParts = dateAsString.split('-')
                var cellDate = new Date(
                  Number(dateParts[0]),
                  Number(dateParts[1]) - 1,
                  Number(dateParts[2]),
                )
                if (
                  filterLocalDateAtMidnight.getTime() === cellDate.getTime()
                ) {
                  return 0
                }
                if (cellDate < filterLocalDateAtMidnight) {
                  return -1
                }
                if (cellDate > filterLocalDateAtMidnight) {
                  return 1
                }
              },
            }
          }
          data.sortable = data.sortable === 'true'
          data.resizable = data.resizable === 'true'
          data.field = data.field.toLowerCase()
          // data.cellClassRules = data.rules
          data.tooltipField = data.field
          data.tooltipComponent = 'customTooltip'
          data.cellStyle = this.cellStyling
          data.fromtable = paramdiatas
          contohdata[data.field] = ''
          delete data.id
          delete data.allowNull
          delete data.data_type
          delete data.table_name
          delete data.isHide
          delete data.reftable
          delete data.retreffield
          columnDefs.push(data)
        }
      }
      return columnDefs
    },
    cellStyling(params) {
      console.log('params', params)
      var targetIndex = params.data.errorColumn.findIndex(
        (x) => x.columnName.toLowerCase() === params.colDef.field,
      )
      if (targetIndex >= 0) {
        return { color: 'red', backgroundColor: '#e68983' }
      }
      // if (params.value === 'Error') {
      // console.log('params', params)
      // mark police cells as red
      // return { color: 'red' }
      // }
      return null
    },
    onFirstDataRendered() {
      // this.autoSizeAll(false, { autosizecolumn: true })
    },
    onCellValueChanged(params) {
      // console.log('params', params)
      // var targetTabledata = this.tableData.findIndex(x => x.formName === params.colDef.fromtable)
      var undoSize = params.api.getCurrentUndoSize()
      this.input.undoInput = undoSize
      this.input.undoInputdisable = undoSize < 1
      var redoSize = params.api.getCurrentRedoSize()
      this.input.redoInput = redoSize
      this.input.redoInputdisable = redoSize < 1
      if (this.mode === 'oncreate') {
        if (params.data.newdata) {
          var target = this.changedData.findIndex(
            (x) => x.id === params.data.id,
          )
          params.data.fromtable = params.colDef.fromtable
          if (target < 0) {
            this.changedData.push(params.data)
          } else {
            this.changedData[target] = params.data
          }
          // console.log('this.changedData', this.changedData)
        } else {
          // eslint-disable-next-line no-redeclare
          var target = this.rowData.findIndex((x) => x.id === params.data.id)
          var backupTarget = this.backupRowData.findIndex(
            (x) => x.id === params.data.id,
          )
          // console.log('this.rowData[target] before:', this.rowData[target])
          this.rowData[target] = this.$g.clone(
            this.backupRowData[backupTarget],
          )
          // console.log('this.rowData[target] after:', this.rowData[target])
          // console.log('this.backupRowData[backupTarget]', this.backupRowData[backupTarget])
          // // this.componentKey += 1
          this.changedData = []
          this.input.undoInput = 0
          this.input.undoInputdisable = true

          this.input.redoInput = 0
          this.input.redoInputdisable = true
          lou.shownotif('Anda sedang dalam mode create')
        }
      } else {
        this.mode = 'onupdate'
        // eslint-disable-next-line no-redeclare
        var targetChanged = this.changedData.findIndex(
          (x) =>
            x.id === params.data.id && x.fromtable === params.colDef.fromtable,
        )
        params.data.fromtable = params.colDef.fromtable
        if (targetChanged < 0) {
          // var cd = {}
          // cd.id = params.data.id
          // cd[params.column.colId] = value.value
          // console.log('cd[params.column.colId]', cd)
          // console.log('value.value', value)
          this.changedData.push(params.data)
        } else {
          this.changedData[targetChanged][params.column.colId] =
            params.newValue
        }
        // this.componentKey += 1
      }
      // console.log('targetTabledata', targetTabledata)
      console.log('this.changedData', this.changedData)
    },
    checkData() {
      // this.
    },
    cancelaction() {
      // console.log('this.mode', this.mode)
      // var changedRowNode = []
      console.log('this.dataResponse', this.dataResponse)
      console.log('this.backupRowData', this.backupRowData)
      if (this.changedData.length >= 10) {
        Modal.confirm({
          title: 'Cancel editing?',
          content:
            this.changedData.length +
            ' Data has not been saved yet! Are you sure?',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk: () => {
            this.dataResponse = this.$g.clone(this.backupRowData)
          },
          onCancel: () => {
            // console.log('Cancel')
          },
        })
      } else {
        this.dataResponse = this.$g.clone(this.backupRowData)
      }
      this.mode = 'netral'
      this.changedData = []
      this.input.undoInput = 0
      this.input.undoInputdisable = true

      this.input.redoInput = 0
      this.input.redoInputdisable = true
      this.componentKey++
      // for (let i = 0; i < this.input.undoInput; i++) {
      //   this.gridApi.undoCellEditing()
      // }
    },
    async saveChanges() {
      /* {
        form08s: [{...}, {...}, {...}]
      } */
      var groupbytable = this.changedData.reduce(function (r, a) {
        if (a.fromtable !== undefined && a.fromtable !== null) {
          r[a.fromtable] = r[a.fromtable] || []
          r[a.fromtable].push(a)
        }
        return r
      }, Object.create(null))
      // console.log('groupbytable', groupbytable)
      var key = Object.keys(groupbytable)
      // console.log('key', key)
      for (let i = 0; i < key.length; i++) {
        const element = key[i]
        var fd = []
        fd = groupbytable[element]
        // console.log('fd', fd)
        // console.log('this.allGridConfig', this.allGridConfig)
        // var column = this.allGridConfig[element]
        // console.log('element', element)
        // var filteredColumn = column.filter(
        //   (x) => x.cellEditor === 'dateEditor',
        // )
        // for (let u = 0; u < fd.length; u++) {
        //   const er = fd[u]
        //   for (let q = 0; q < filteredColumn.length; q++) {
        //     const eq = filteredColumn[q]
        //     if (er[eq.field]) {
        //       er[eq.field] = moment()
        //     }
        //   }
        // }
        var res = await lou.updateMasterMany(element, fd, false)
        if (res) {
          lou.shownotif('Success', 'Form ' + element + ' update successfully!')
        }
      }
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows()
      this.selectedData = selectedRows
      // console.log('selectedRows', selectedRows[0].karat)
      // console.log('this.columnCurrency', this.columnCurrency)
    },
    addNullData() {
      this.mode = 'oncreate'
      var fd = {}
      this.columnDefs.forEach((element, index) => {
        var booltgl =
          element.field.includes('tgl') ||
          element.field.includes('tanggal') ||
          element.field.includes('Tanggal') ||
          element.field.includes('TGL') ||
          element.field.includes('Tgl')
        if (
          element.field === 'flagdetail' ||
          element.field === 'FlagDetail' ||
          element.field === 'Flagdetail'
        ) {
          fd[element.field] = 'D01'
        } else if (booltgl) {
          fd[element.field] =
            element.field === 'tglpenilaianterakhir'
              ? null
              : moment().format('YYYY-MM-DD')
        } else if (element.cellEditor === 'numericEditor') {
          fd[element.field] = 0
        } else {
          fd[element.field] = ''
        }
      })
      fd.id = this.$uuid.v4()
      fd.newdata = true
      this.rowData.splice(0, 0, fd)
    },
    removeOrDelete() {
      // console.log('this.selectedData', this.selectedData)
      if (this.selectedData === null) {
        lou.shownotif(
          'Anda belum memilih data atau data yang anda pilih harus tidak boleh lebih dari 1',
        )
      } else {
        if (this.selectedData[0].newdata) {
          var indexTarget = this.rowData.findIndex(
            (x) => x.id === this.selectedData[0].id,
          )
          this.rowData.splice(indexTarget, 1)
        } else {
          this.confirmationDelete(this.selectedData[0].id)
          // send to api
        }
      }
    },
    refreshTable() {
      var params = {
        force: true,
        suppressFlash: false,
      }

      // this.gridOptions.api.refreshHeader()
      this.gridOptions.api.refreshCells(params)
      // console.log('kerefresh kok')
    },
    onGridReady(params, form) {
      // console.log('form', form)
      var data = this.tableData[form]
      data.gridOptions = {}
      // console.log('params', params)
      // params.api.sizeColumnsToFit()
      data.gridApi = params.api
      data.gridColumnApi = params.columnApi
      // console.log('data', data)
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    undo() {
      this.gridApi.undoCellEditing()
    },
    redo() {
      this.gridApi.redoCellEditing()
    },
    currencyFormatter(params) {
      var ret = params.value === null ? 'NaN' : lou.curency(params.value)
      return ret === 'NaN' ? '' : ret
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    titleFormat(word) {
      return lou.capitalizeTheFirstLetterOfEachWord(word)
    },
  },
}
</script>

<style></style>
